import React from "react";
import { useState } from "react";
import image111 from "../images/homepage/111.png"
import image222 from "../images/homepage/222.png"
import image333 from "../images/homepage/333.jpg"
import image444 from "../images/homepage/444.png"
import image5 from "../images/homepage/5.jpg"
import image666 from "../images/homepage/666.png"

const ProductArea = () => {
    const [images] = useState([
        {
            "title": "Ai.morpheus #88",
            "image": image111,
            "bid": "Owned by E85696",
            "price": "0.05 ETH",
            "link": "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/39266985967464638182036215616873438919621870542242035517942535800827909505025"
        },
        {
            "title": "Ai.morpheus #97",
            "image": image222,
            "bid": "Owned by AnadelNFT",
            "price": "0.07 ETH",
            "link": "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/39266985967464638182036215616873438919621870542242035517942535810723514155009"
        },
        {
            "title": "Ai.morpheus #90",
            "image": image333,
            "bid": "Owned by E63428",
            "price": "0.03 ETH",
            "link": "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/39266985967464638182036215616873438919621870542242035517942535803026932760577"
        },
        {
            "title": "Ai.morpheus #73",
            "image": image444,
            "bid": "Owned by not_yournft",
            "price": "0.03 ETH",
            "link": "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/39266985967464638182036215616873438919621870542242035517942535784335235088385"
        },
        {
            "title": "Ai.morpheus #92",
            "image": image666,
            "bid": "Owned by not_yournft",
            "price": "0.03 ETH",
            "link": "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/39266985967464638182036215616873438919621870542242035517942535805225956016129"
        },
    
    ])

    return (
        <div className="rn-product-area rn-section-gapTop masonary-wrapper-activation">
            <div className="container">
                <div className="row align-items-center mb--30">
                    <div className="col-lg-4">
                        <div className="section-title">
                            <h3 className="title mb--0">Recent Sales</h3>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="grid-metro5 d-flex justify-content-start flex-wrap">
                        {images.map((image) => (
                            <div className="grid-metro-item">
                                <div className="product-style-one no-overlay with-placeBid">
                                    <div className="card-thumbnail">
                                        <a href={image.link}><img src={image.image} alt="NFT_portfolio" /></a>
                                        <a href={image.link} className="btn btn-primary">Place Bid</a>
                                    </div>
                                    <div className="product-share-wrapper">

                                    </div>
                                    <a href={image.link}><span className="product-name">{image.title}</span></a>
                                    <span className="latest-bid">{image.bid}</span>
                                    <div className="bid-react-area">
                                        <div className="last-bid">{image.price}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductArea