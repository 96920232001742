import React from "react";
import { useState } from "react";
import image1 from "../images/homepage/1.png"
import image2 from "../images/homepage/2.png"
import image3 from "../images/homepage/3.jpg"
import image4 from "../images/homepage/4.jpg"
import image5 from "../images/homepage/5.jpg"
import image6 from "../images/homepage/6.jpg"

const HeaderImages = () => {

    const [images] = useState([
        {
            "title": "White Walker",
            "image": image1
        },
        {
            "title": "CZ Binance",
            "image": image2
        },
        {
            "title": "Nikola Tesla",
            "image": image3
        },
        {
            "title": "Angels",
            "image": image4
        },
        {
            "title": "Lazy Lions NFT",
            "image": image5
        },
        {
            "title": "Elizabeth Tower",
            "image": image6
        }
    ])

    return (
        <div className="row g-4">
            {images.map((image) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="slide-small-wrapper">
                        <div className="thumbnail thumbnail-overlay">
                            <a href="https://opensea.io/collection/aimorpheus">
                                <img className="w-100" src={image.image} alt="Nft_Profile"/>
                            </a>
                        </div>
                        <div className="read-wrapper">
                            <h5 className="title"><a href="#">{image.title}</a></h5>
                            <span>Creation inspired by</span>
                        </div>
                    </div>                                
                </div>
            ))}
        </div>
    );
};

export default HeaderImages