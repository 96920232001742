import React, { useState } from "react";
import { Accordion } from "react-bootstrap";



const FAQ = () => {

    const [faqs] = useState([
        {
            "title": "What are your methods for producing art ?",
            "body": `Artificial intelligence and machine learning are being used by Ai.Morpheus to produce a limited collection of 1000 machine-generated artworks. Our NFT creation technique employs generative adversarial networks (GANs), a family of machine learning frameworks (TensorFlow and PyTorch) that generate pictures based on text input. The development of the GAN, training the GAN using custom datasets, and creating the art are the three elements of this approach.`
        },
        {
            "title": "What are the advantages of owning a Morpheus NFT ?",
            "body": `Every item in our Ai.Morpheus collection comes with a unique membership into the Morpheus network ecosystem, which rewards all members with a variety of privileges including physical art, access to our virtual gallery, free NFT minting, and much more...`
        },
        {
            "title": "What is Morpheus Maze🌌 ?",
            "body": `Morpheus Maze is a new NFT project by Morpheus network. 
    
            Owning a Morpheus Maze gives you exclusive access to future projects in the Morpheus Neural Network. Lots of benefits like: \n
            ☑️ Free physical copies of Ai.Morpheus NFTs
            ☑️ Members-only NFT drops
            ☑️ Direct contact with the team to shape the future of Ai.Morpheus and many more!
  
            All holders of the Morpheus Maze collection will be granted various levels of membership with different tiers.`
        },
        {
            "title": "When is the mint ?",
            "body": `
            Public Presale - 100 NFT's minted at 0.03 ETH - Ongoing @ https://opensea.io/collection/aimorpheus

            Public Sale - 900 NFT's minted at 0.04 ETH - TBA

            Every Ai.Morpheus NFT owner will get whitelisted and rewarder with a free 🌌 Morpheus Maze NFT and access to Morpheus Intel App`
        },
        {
            "title": "Why is one portion of the collection on Polygon and the other on the Ethereum blockchain ?",
            "body": `We decided to provide our users the choice of minting on Polygon without paying any gas costs or the traditional method on the ETH blockchain because our entrance price of 0.03ETH is very affordable and built that way so that everyone can obtain an access point into the project.`
        },
        {
            "title": "How can you receive a free high-quality physical copy of your NFT ?",
            "body": `You will be allowed unique access to the free claim page if you own a Morpheus NFT, where you will submit your details and have a high-quality physical piece sent to your dor at no additional cost.`
        },
    ])


    return (
        <div className="rn-service-area rn-section-gapTop">
            <div className="container">
                <h3 class="title">Frequently Asked Questions</h3>
                <Accordion defaultActiveKey="0">
                    {faqs.map((item, index) => (
                        <Accordion.Item eventKey={index.toString()}>
                            <Accordion.Header>{item.title}</Accordion.Header>
                            <Accordion.Body className="new-line">
                                {item.body}
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </div>

    )
}

export default FAQ