import React, { useState } from "react";
import { Accordion } from "react-bootstrap";



const FAQRobots = () => {

    const [faqs] = useState([
        {
            "title": "How can I get whitelisted and receive a free Ai Robot NFT?",
            "body": `You must own at least one Morpheus NFT to be whitelisted. Submit your ETH/Polygon address above after you have at least on Morpheus NFT. `
        },
        {
            "title": "When is the mint ?",
            "body": `
            Whitelist Presale - Ai Robots will be minted for a cost of 0 MATIC each.

            Public Sale - Ai Robots will be minted for a cost of 75 MATIC each.

            Mint Date - May 22

            Every Ai Robot NFT owner will get rewarder with a free access to Morpheus Intel App`
        },
        {
            "title": "What are your methods for producing 3D art ?",
            "body": `Artificial intelligence and machine learning are being used by Ai.Morpheus to produce a limited collection of 2222 machine-generated 3D robots. Our 3D creation technique employs generative adversarial networks (GANs), a family of machine learning frameworks (TensorFlow and PyTorch) that generate pictures and 3D objects based on text input. The development of the GAN, training the GAN using custom datasets, and creating the art are the three elements of this approach.`
        },
        {
            "title": "What is Ai Robots NFT ?",
            "body": `Ai Robots is a new NFT project by Morpheus network. 
    
            Owning a Ai Robot gives you exclusive access to future projects in the Morpheus Neural Network. Lots of benefits like: \n
            ☑️ Free physical copies of Ai.Morpheus NFTs
            ☑️ Members-only NFT drops
            ☑️ Direct contact with the team to shape the future of Ai.Morpheus and many more!
  
            All holders of the Ai Robots collection will be granted various levels of membership with different tiers.`
        },
        {
            "title": "What are the advantages of owning a Morpheus NFT ?",
            "body": `Every item in our Ai.Morpheus collection comes with a unique membership into the Morpheus network ecosystem, which rewards all members with a variety of privileges including physical art, access to our virtual gallery, free NFT minting, and much more...`
        },
    ])


    return (
        <div className="rn-service-area rn-section-gapTop">
            <div className="container">
                <h3 class="title">Ai Robots FAQ</h3>
                <Accordion defaultActiveKey="0">
                    {faqs.map((item, index) => (
                        <Accordion.Item eventKey={index.toString()}>
                            <Accordion.Header>{item.title}</Accordion.Header>
                            <Accordion.Body className="new-line">
                                {item.body}
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </div>

    )
}

export default FAQRobots