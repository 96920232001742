import React, { useState } from "react";

const OurTeam = () => {
    const [team] = useState([
        {
            "name": "Python390",
            "role": "Discord: Python390#9333",
            "about": "Blockchain / Web 3.0 Developer",
            "image": "/images/img-one.jpg"
        },
        {
            "name": "Fury",
            "role": "Discord: Fury#5476",
            "about": "Founder & Developer",
            "image": "/images/img-two.jpg"
        },
        {
            "name": "CyberFunk",
            "role": "Discrod: CyberFunk#3644",
            "about": "Community Manager & Content Writer",
            "image": "/images/img-three.jpg"
        },
    ])

    return (
        <div className="rn-service-area rn-section-gapTop">
            <div className="container">
                <h3 className="title">AI.Morphues Team</h3>
                <div className="d-flex flex-wrap flex-lg-nowrap">
                    {team.map((member) => (
                        <div className="member-card d-flex flex-column justify-content-center align-items-center flex-fill">
                            <img style={{borderRadius: '8px'}} className="team-image" src={member.image} />
                            <h4>{member.name}</h4>
                            <p><small>{member.role}</small></p>
                            <p>{member.about}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default OurTeam