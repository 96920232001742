import React from "react";
import { useState } from "react";
import image11 from "../images/homepage/11.png"

const SliderThumbnail = () => {

    const [images] = useState([
        {
            "title": "Vitalik Buterin from the perspective of Morpheus",
            "image": image11
        }
    ])

    return (
        <div className="slider slider-activation-banner-3">
            {images.map((image) => (
                <div className="slider-thumbnail thumbnail-overlay">
                    <a href="product-details.html">
                        <img className="w-100" src={image.image} alt="NFT_portfolio"/></a>
                    <div className="read-wrapper">
                        <h5><a href="product-details.html">Ai.morpheus #11</a></h5>
                        <span>{image.title}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SliderThumbnail