import React from "react";
import HeaderImages from "./HeaderImages";
import SliderThumbnail from "./SliderThumbnail";

const MainHeader = () => {
    return (
        <div className="banner-three slider-style-3 pt--70">
            <div className="container">
                <div className="row g-4">
                    <div className="col-lg-5">
                        <div className="wrapper">
                            <SliderThumbnail/>
                        </div>
                    </div>
    
                    <div className="col-lg-7">
                            <HeaderImages/>
                    </div>
    
                </div>
            </div>
        </div>
    );
}

export default MainHeader