
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { Link } from "react-router-dom";
import { fetchData } from "./redux/data/dataActions";
import Header from "./components/MainHeader";
import HeroSection from "./components/HeroSection";
import ProductsArea from './components/ProductsArea';
import Roadmap from "./components/Roadmap";
import Footer from "./components/Footer";
import Logo from './images/logo.jpg';
import FAQ from "./components/FAQ";
import OurTeam from "./components/OurTeam";
import { postOrder } from "./services/RestService";
import CustomModal from "./components/CustomModal";
import { Modal, Form, Button, Image, Row, Col } from "react-bootstrap";
import ReactGA from 'react-ga';

const logo = Logo
const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

function App() {

  const TRACKING_ID = "G-G0XCQ3Z9WH";
  ReactGA.initialize(TRACKING_ID);

  const [formState, setFormState] = useState({
    email: "",
    first_name: "",
    last_name: "",
    adress: "",
    number: "",
    zip: "",
    art_number: "",
    eth_adress: "24515125"
  })
 
  const onFormSubmit = e => {
    e.preventDefault();
    postOrder(formState);
  }

  const [modalState, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [item, setItem] = useState(false);

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });
  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 50) {
      newMintAmount = 50;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    fetchAdressUsers();
  }, [])


  useEffect(() => {
    fetchAdressUsers();
  }, [blockchain.account])

  const fetchAdressUsers = () => {
    fetch("https://metarama.world/api/get/get-enrolled-users.php")
      .then(res => res.json())
      .then(
        (result) => {
          if (blockchain.account) {
            for (let i = 0; i < result.length; i++) {

              if (result[i].toUpperCase() === (blockchain.account.toUpperCase().toString())) {
                setItem(true);

              }
            }

          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  };

  const onClickShowModal = () => {
    if (item == true) {
      setShowModal(true);
    } else {
      alert("Only NFT owners are eligible to claim physical pieces!")
    }
  }

  return (
    <>
      <Modal
        className=""
        size="xl"
        show={modalState}
      ><Row>
          <Col>
            <Modal.Dialog style={{ width: "100%", border: "none" }}>
              <Modal.Header>
                <Modal.Title className="text-dark">Add your information</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form onSubmit={onFormSubmit}>
                  <Form.Group className="mb-3" controlId="1">
                    <Form.Label>Email</Form.Label>
                    <Form.Control value={formState.email}
                      onChange={e => setFormState({...formState, email: e.target.value })} placeholder="Email" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="2">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control value={formState.first_name}
                      onChange={e => setFormState({...formState, first_name: e.target.value })} placeholder="First Name" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control value={formState.last_name}
                      onChange={e => setFormState({...formState, last_name: e.target.value })} placeholder="Last Name" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="4">
                    <Form.Label>Adress</Form.Label>
                    <Form.Control value={formState.adress}
                      onChange={e => setFormState({...formState, adress: e.target.value })} placeholder="Adress" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="5">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control value={formState.number}
                      onChange={e => setFormState({...formState, number: e.target.value })} placeholder="Phone Number" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="6">
                    <Form.Label>Zip</Form.Label>
                    <Form.Control value={formState.zip}
                      onChange={e => setFormState({...formState, zip: e.target.value })} placeholder="Zip" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="7">
                    <Form.Label>Art number</Form.Label>
                    <Form.Control value={formState.art_number}
                      onChange={e => setFormState({...formState, art_number: e.target.value })} placeholder="Art number" />
                  </Form.Group>
                </Form>
              </Modal.Body>

              <Modal.Footer>
                <Button onClick={handleClose} variant="secondary">Close</Button>
                <Button onClick={onFormSubmit} variant="primary">Submit</Button>
              </Modal.Footer>
            </Modal.Dialog>
          </Col>
          <Col className="d-flex justify-content-center align-items-center">
            <Image src="/images/order.png"></Image>
          </Col>
        </Row>
      </Modal>
      {error ? (<></>) : (
        <>
          
        </>

        //<div onClick={onClickShowModal} className="d-flex justify-content-center text-center align-items-center row pop-up-send-art position-fixed m-5">
        //<img className="claim-icon" width={20} src="/images/free-delivery.png" />
        //<p className="claim-text">Claim your physical piece!</p>
        //</div>

      )}
      <header className="rn-header haeder-default black-logo-version header--fixed header--sticky">
        <div className="container">
          <div className="flex-wrap header-inner d-flex">
            <div className="flex-fill justify-content-start">
              <div className="logo-thumbnail logo-custom-css ">
                <a className="logo-light" href="/"><img src={logo} alt="nft-logo" /></a>
              </div>
            </div>
            <div className="m-0 p-0 flex-fill text-center">
              <h4 className='justify-content-center m-0'>AI.Morphues</h4>
            </div>
            <div className="flex-fill m-0">
              <div className="">
                <div className="mt-4 mb-4 align-items-end d-flex justify-content-end">

                  {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                    <>
                    </>
                  ) : (
                    <>
                      {(data.totalSupply) >= CONFIG.MAX_SUPPLY || blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <>
                          {blockchain.errorMsg !== "" ? (
                            alert(blockchain.errorMsg)
                          ) : null
                          }
                        </>
                      ) : (
                        <>
                          <a className="btn btn-primary-alta">Connected</a>
                        </>


                      )}

                    </>
                  )}
                <Link to="/robots"><a className="btn btn-primary-alta" onClick={(e) => {
                            //e.preventDefault();
                            //dispatch(connect());
                            //getData();
                          }}>AI.Robots</a></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {(data.totalSupply) >= CONFIG.MAX_SUPPLY || blockchain.account === "" ||
        blockchain.smartContract === null ? (<></>) :
        (
          <div className="mint-window-wrapper fade-in-animation">
            <div className="container justify-content-center align-items-center text-center">
              <h3>Minted</h3>
              <h4 className="">{data.totalSupply} / {CONFIG.MAX_SUPPLY}</h4>
              <p>Price</p>
              <p>1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                {CONFIG.NETWORK.SYMBOL}.</p>
              <p>Amount to mint</p>
              <div className="d-flex justify-content-center">
                <div className="w-50 d-flex justify-content-around align-items-center text-center">
                  <img className="inc-dec-icons" style={{ lineHeight: 0.4 }}
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      decrementMintAmount();
                    }}
                    width={20} src="/images/minus.png" />
                  <h3 className="m-0">{mintAmount}</h3>
                  <img className="inc-dec-icons" disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      incrementMintAmount();
                    }}
                    width={20} src="/images/plus.png" />
                </div>
              </div>
              <a className="mt-5 mint-btn btn btn-primary-alta btn-large" disabled={claimingNft ? 1 : 0}
                onClick={(e) => {
                  e.preventDefault();
                  claimNFTs();
                  getData();
                }}>{claimingNft ? "BUSY" : "COMING SOON!"}</a>
            </div>
          </div>
        )}
      <CustomModal showModal={modalState} />
      <HeroSection />
      <Header />
      <ProductsArea />
      <Roadmap />
      <OurTeam />
      <FAQ />
      <div className="m-5"></div>
      <Footer />
    </>
  );
}

export default App;
