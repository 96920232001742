import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";

const CustomModal = (modalState) => {
    const [showIt, setShow] = useState(modalState.showModal);

    return (
        <></>
    )

}

export default CustomModal