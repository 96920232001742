import React from "react";
import { useState } from "react";

const Roadmap = () => {
    const [roadmap_data] = useState([
        {
            "small_title": "📍Q1 - 2022",
            "title": "Ai. Morpheus NFT's are released for minting.",
            "description": "Free physical copies of all Ai. Morpheus works so that we can create true sentimental value with our art that will linger not only in the metaverse but also in the physical world.",
            "icon": "/images/q1-img.png"
        },
        {
            "small_title": "📍Q2 - 2022",
            "title": "Launch of Morpheus Maze NFT.",
            "description": "Owning a Morpheus Maze gives you exclusive access to future projects in the Morpheus Neural Network.",
            "icon": "/images/q2-img.png"
        },
        {
            "small_title": "📍Q3 - 2022",
            "title": "Launch of Morpheus Intel application with NFT trend following algorithms.",
            "description": "Integration of most popular social networks and trend following algorithms in Morpheus Intel application.",
            "icon": "/images/q3-img.png"
        },
        {
            "small_title": "📍Q4 - 2022",
            "title": "Our journey will be extended until the end of time with the release of Roadmap 2.0",
            "description": "",
            "icon": "/images/q4-img.png"
        },
    ])
    return (
        <div className="rn-service-area rn-section-gapTop">
            <div className="container">
                <div className="row">
                    <div className="col-12 mb--50">
                        <h3 className="title">Roadmap</h3>
                    </div>
                </div>
                <div className="row g-5">
                    {roadmap_data.map((data) => (
                        <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="rn-service-one color-shape-7">
                                <div className="inner">
                                    <div className="icon">
                                        <img src={data.icon} alt="Shape" />
                                    </div>
                                    <div className="subtitle">{data.small_title}</div>
                                    <div className="content">
                                        <h4 className="title"><a>{data.title}</a></h4>
                                        <p className="description">{data.description}</p>
                                        <a className="read-more-button"><i className="feather-arrow-right"></i></a>
                                    </div>
                                </div>
                                <a className="over-link"></a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Roadmap