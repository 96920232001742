export async function postOrder(jsonData) {
    console.log(jsonData)
     var data = JSON.stringify(jsonData);
      
      var xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      
      xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
          console.log(this.responseText);
        }
      });
      
      xhr.open("POST", "https://orders-baf7.restdb.io/rest/orders");
      xhr.setRequestHeader("content-type", "application/json");
      xhr.setRequestHeader("x-apikey", "623d034167937c128d7c918d");
      xhr.setRequestHeader("cache-control", "no-cache");
      
      xhr.send(data);
};

export async function addEthereumAdress(jsonData) {
    var data = JSON.stringify(jsonData);
    
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    
    xhr.open("POST", "https://whitelist-f47f.restdb.io/rest/whitelist");
    xhr.setRequestHeader("content-type", "application/json");
    xhr.setRequestHeader("x-apikey", "62575b3a67937c128d7c9784");
    xhr.setRequestHeader("cache-control", "no-cache");
    
    xhr.send(data);

    return new Promise((resolved, rejected) => {
      xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
          resolved(true)
        }
      });
    })
};